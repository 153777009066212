.NavContainer {
/*  border: solid 1px red;*/
  height: 90px;
  width: auto;
  background-color:rgb(15 26 63);
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
  --above-all-in-container: 10000;
/*  box-shadow: 0px 1px  30px rgb(15 26 69);*/
}

.NavLinks {

}
.Navlogo{
  color: white;
  font-weight: 500;
  font-size: 35px;
}

.NavContainer{
}
.NavList_Elements .contact{
  background-color: white;
  padding: 2px;
  border-radius: 5px;
  color: rgb(15 26 63);
}
.NavList_Elements .contact:hover{
  background-color: rgb(15 26 63);
  color: white;
  transition: all 0.5s ease-in;
}

.NavMenuIcon {
  display: none;
  color: white;

}
.NavList_Elements{
   margin: auto;
}

.NavList_Elements ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;

}

.NavList_Elements ul li:not(:last-child) {
  margin-right: 40px;
}

.NavList_Elements ul a {
  color: white;
  font-size: 19px;
  text-align: center;
  font-weight: 400;
  text-decoration: none;
}
.NavList_Elements li{
   /* color:;
    position: relative;*/
}
/*.NavList_Elements ul a::after{
      content: '';
    height:2px;
    width:0px;
    background-color: lightgrey;
    position: absolute;
    bottom: 0;
    left:0;
    transition: 0.3s ease;
}*/
.NavList_Elements ul a:hover::after{
  width: 34px;
}

.NavList_Elements ul a.active {
}
.NavList_Elements ul a:hover{
  color: grey;
    transition: all 0.3s ease-in;
    width: 20%;
    border-bottom:solid 2px lightgrey;

}

/*.NavList_Elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}*/


@media only screen 
and (min-device-width : 240px) 
and (max-device-width : 719px)
{
  .NavContainer{
    width:auto;
  }

  .NavList_Elements ul a {
  font-size: 18px;
}
.NavMenuIcon {
  font-size: 40px;
  display: block;
  cursor: pointer;
  position: absolute;
  right: 0;
  margin-right: 10px;
}

.NavList_Elements{
  position: absolute;
  right: 0;
  top: 80px;
  width: 0px;
  background-color:rgb(15 26 63);
  height: calc(100vh - 60px);
  transition: all 0.3s ease-in;
  overflow: hidden;
}
.logout{
    /* corner lay endehon 0 maderg becha */
  position: absolute;
  margin-top: 55%;
  left: 45%;
}
.NavList_Elements.active {
  width: 250px;
  height: 300px;
}

.NavList_Elements ul {
  display: flex;
  flex-direction: column;
}

.NavList_Elements ul li {
  margin-right: unset;
  margin-top: 15px;
}
}
@media only screen 
and (min-device-width : 720px) 
and (max-device-width : 1024px) 
and (orientation : portrait)
 {
.NavList_Elements ul li:not(:last-child) {
  margin-right: 25px;
  font-size: 10px;
}
.NavContainer{
}
}

