.contact-body{
	background-color: rgb(0, 0, 0, 1.0);
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes wait {
    from { transform: translateY(20px); }
    to { transform: translateY(20px); }
}

@keyframes slideInFromBottom {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}
@keyframes fadein {
  from { opacity: 0}
  to   { opacity: 1}
}
.contact-contnair{
	width: 70%;
	height: auto;
	margin: auto;
	background-color: rgb(15 26 63);
	 -webkit-box-shadow: 0px 0px 16px -8px black;
    box-shadow: 0px 0px 30px -8px lighgrey;
    margin-top: 5%;
    transition: all 0.5s ease;
    border-radius: 2%;
    box-shadow: 0px 1px  30px rgb(15 26 69);
    animation: fadein 5s;
/*      animation: 1s ease-out 0s 1 slideInFromLeft;*/
/*      animation: 3s ease-out 0s 1 wait, 0.21s ease-out 3s 1 slideInFromBottom;*/
/*          opacity: 0;*/
/*    -moz-transition: opacity 1.5s;
    -webkit-transition: opacity 1.5s;
    -o-transition: opacity 1.5s;
    transition: opacity 1.5s;*/
}
.contact-contnair:hover{
	opacity: 90%;
}
.contact-contnair h1{
	color: white;
	text-align: center;
	font-size: 50px;
	font-weight:bold;
	font-family: inherit;
	transition: all 0.5s ease;
}
.contact-contnair p{
	color: white;
	text-align: center;
	margin: 6%;
	font-size: 20px;
	font-family: Arial, Helvetica, sans-serif;;
}
.contactFeedbackContinaire{
	width: 50%;
	margin: auto;
	height: 200px;
	-webkit-box-shadow: 0px 0px 16px -8px lightgrey;
    box-shadow: 0px 0px 30px -8px lighgrey;


}
.contactFeedbackContinaire h2{
	color: white;
	 animation: fadein 5s;
	 animation: 1s ease-out 0s 1 slideInFromLeft .fadein 5s;

}
.contact-Feedback-box{
    padding: 20px;
    align-items: center;
    background-color: rgb(15 26 63);
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 1px  30px lightgrey;

}
.contact-Feedback-input{
	height: 30px;
	width: 50%;
    border-radius: 10px;
    border: 1px solid gray;
    font-size: 18px;
    padding-left: 10px;
    margin: 5px;
}
.textarea{
	width: 52%;
	height: 70px;
	border-radius: 20px;
	padding-left: 10px;
}
.ContactButton{
    border: none;
    padding: 7px;
    border: solid 1px grey;
    border-radius: 5px;
    background-color: rgb(15 26 63);
    font-weight: 500;
    margin-right: 20px;
    cursor: pointer;
    color: white;
}
textarea::-webkit-scrollbar {
    width: 1em;
    display: none;
}

textarea::-webkit-scrollbar-track {
/*    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
}

textarea::-webkit-scrollbar-thumb {
/*  background-color: darkgrey;*/
/*  outline: 1px solid slategrey;*/
}
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {
.contact-contnair{
	width: auto;
}
.contactFeedbackContinaire{
	width: auto;
}
}

