.feed{
    flex:5.5;
}

.feedWrapper{
    padding: 5px;
    width: 100%;
    display: grid;
    margin: 5px;
    grid-template-columns: auto auto auto auto;

}
