
.postWrapper {
    padding: 0px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  margin: 30px 0;
}
.postUsername {
  font-size: 15px;
  font-weight: 500;
  margin: 0 10px;
}

.postDate{
    font-size: 12px;
}

.postCenter{
    margin: 10spx;
}

.postImg{
    margin-top: 0px;
    width: 100%;
    height: 300px;
    object-fit: contain;
}
.modal{
    margin-top: 7%;
}

body.active-modal {
    overflow-y: hidden;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
     overflow-y: scroll;
}

.overlay {
    background: rgba(49,49,49,0.8);
}
.modal-content {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    border-radius: 3px;
    max-width: 900px;
    min-width: 600px;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
}
.btn-readMore{
    background-color: white;
    padding: 0px;
    border: none;
    margin: 5px;
}




