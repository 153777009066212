.login {
  width: 50%;
  height: 500px;
  background-color: ;
  margin: auto;
}
.loginLogo{
    font-size: 40px;
    text-align: center;
    padding: 0px;
    font-family:arial;
    margin-top: 7%;
    position: relative;
    left: -4%;
    color: rgb(15 26 63);
}

.loginBox{
    width: 80%;
    height: 400px;
    margin: auto;
    padding: 20px;
    background-color: rgb(15 26 63);
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.loginInput{
    height: 50px;
    border-radius: 10px;
    border: 1px solid gray;
    font-size: 18px;
    padding-left: 20px;
}

.loginInput:focus{
    outline: none;
}

.loginButton{
    height: 50px;
    border-radius: 10px;
    border: none;
    border:solid rgb(15 26 63);
    color: rgb(15 26 63);
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}
.loginButton:hover{
    background-color: white;
    color: rgb(15 26 63);
}

.loginForgot{
    text-align: center;
    color: #1775ee;
}

.loginRegisterButton{
    width: 100%;
    align-self: center;
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: rgb(15 26 63);
    color: white;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}
@media only screen 
and (min-device-width : 240px) 
and (max-device-width : 719px)
{
.loginLogo{
    font-size: 25px;
 }
.loginRegisterButton{
    width: 100%;
}
.login{
    width: 100%;
}
.loginBox{
    margin: auto;
}

}
@media only screen 
and (min-device-width : 240px) 
and (max-device-width : 1024px) 
and (orientation : portrait)
 {
.login{
    width: 100%;

}
}
@media only screen 
and (min-device-width : 882px) 
and (max-device-width : 1024px)
{
.login{
    width: 100%;
}

}
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px){

.loginBox{
    width:80%;
    margin: auto;
}
}