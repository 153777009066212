.container-Give{
	width:80%;
	height: 300px;
	background-color: white;
	opacity: 60%;
	margin: auto;
	text-align: center;
	font-size: 50px;
	color: rgb(15 26 63);
	font-family: inherit;
}
.Give_contenair {
	width: 90%;
	margin: auto;
	text-align: ;
	background-color:rgb(15 26 63);
	border-radius: 20px;

}
.Give_contenair_2{
	width: 90%;
	margin: auto;
	text-align: ;
	color: white;
	
}

.Give_contenair h1{
	text-align: center;
	margin-top: 0%;
	font-size: 60px;

}
.Give_box_2{
	width:100%;
	height: 300px;
	color: white;
	font-size: ;

}
.Give_box{
	margin-top:;
}
.Give_contenair_2 span{
	color: red;
	font-weight: 500;
}