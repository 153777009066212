@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes wait {
    from { transform: translateY(20px); }
    to { transform: translateY(20px); }
}

@keyframes slideInFromBottom {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 3; }
}
@keyframes fadein {
  from { opacity: 0}
  to   { opacity: 1}
}
.mainContainer{
  display: flex;
  margin-top: 50%;
}
.container{
  margin: auto;
  width: 80%;

}

.silderImg {
    width: auto;
    height: 200px;
    object-fit: contain;
    margin-top: 10px;
      float: left;
}
.Image_container{
  border: solid 2px lightgrey;
  background-color: white;
  height: 212px;
  cursor: pointer;
  margin: 0px 5px 60px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
      transition: 0.3s ease;
      animation:slideInFromBottom 5s;


}
.Image_container p{
  text-align: center;
  color:black;
  font-size:12px;

}
.Image_container:hover{
  -webkit-box-shadow: -10px 0px 36px -10px black;
  box-shadow: -10px -10px 66px -10px black;
}


                      /* home header video part continaer */
.Header_container_main{
/*  border: solid 5px red;*/
  width: 100%;
  height: auto;
/*    -webkit-box-shadow: 0px 0px 36px -8px lightgrey;
  box-shadow: 0px 0px 50px -5px rgb(15 26 63);*/
}

.Header_container_main video {
  top:0;
/*  border: solid 2px yellow;*/
  object-fit: cover;
  width: 99.7%;
  height: auto;
  position: absolute;
  z-index: -6;
  left: 0%;
}


.headerbg {
  position: ;
  width: auto;
  height: 668px;
  z-index: 0;
  top: 0;
  background-color: black;
  opacity: 0.7;
/*  border: solid 3px lightgreen;*/
}
header {
/*  border:solid 3px yellow;*/
  height:auto ;
/*  width: 100%;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}
header h1 {
  z-index: 1;
  color: white;
  font-size: 64px;
  margin-top:-60%;
}
.opened {
  transform: translateX(0%)!important;
}
                   

                               /*middle page  for home page*/
.middlePage{
/*  border: solid 2px red;*/
margin-top: 5%;
  width: 100%;
  height:400px;

}
.middlePage_2{
/*    border: solid 2px black;*/
    margin: auto;
    width: 100%;
    height:auto;

}
.middlePage_box{
/*  border: solid 2px red;*/
  margin-top: 0%;
  text-align: center;
}
.middlePage_box h2{
  margin-top: 15%;
  font-size: 40px;
  text-align: center;
}
.middlePage .middlePage_box{
}
.middlePage_continaer{
  width:49%;
/*  border:solid 2px green;*/
  height: 400px;
  float: left;

}
.middlePage img{
  width:100%;
  border-radius: 20px;
  height: 380px;
}


                       /********** Responsive Design ********/
@media(max-width:900px)
{
  .Header_container_main video {
    width:99%;
    margin-top: 5%;
    height: 545px;
}
.headerbg{
  width:99%;
    height: 490px;
/*    border: solid 3px red;*/
}
  header h1{
    font-size: 40px;
  }
.middlePage{
  height:auto;
}
.middlePage_2{
  width: 100%;
}
.middlePage_continaer{
  float: none;
  width: auto;
  height: auto;

}
.middlePage img{
  position: unset;
  width: 100%;
  height: auto;
}
}
@media(max-width:768px)
{
    .Header_container_main video {
    width:;
  height: 545px;
}
  .Header_container_main{
/*    height: 300px;*/
  }
.middlePage_continaer{
  float: none;
  width: auto;
  height: auto;

}
.middlePage img{
  position: unset;
  width: 100%;
  height: auto;
}
  header h1{
    font-size: 40px;
    position: absolute;
    top: 100%;

  }
}
@media(max-width:568px)
{
    .Header_container_main video {
    width:;
  height: 545px;
}
  header h1{
    font-size: 40px;
  }
.middlePage_continaer{
  float: none;
  width: auto;
  height: auto;

}
.middlePage img{
  position: unset;
  width: 100%;
  height: auto;
}
}
/* main *
.main-title {
  font-size: 48px;
  color: #01bf71;
}
.main-p {
  font-size: 22px;
  font-weight: 500;
}
main {
  padding: 3rem 0;
  background-color: #fafafa;
  width: 100%;
}*/
/*h1,
h2,
h3,
h4 {
  letter-spacing: 0.1rem;
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
.section {
  width: 90vw;
  margin: 5rem auto;
  max-width: 1170px;
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

.icon {
  font-size: 3rem;
  color: hsl(21, 62%, 45%);
}

.section-center {
  margin: 0 auto;
  margin-top: 4rem;
  width: 80vw;
  height: 450px;
  max-width: 800px;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
}

article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s linear;
}

article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}
article.lastSlide {
  transform: translateX(-100%);
}
article.nextSlide {
  transform: translateX(100%);
}

.person-img {
  border-radius: 50%;
  margin-bottom: 1rem;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 4px solid hsl(210, 31%, 80%);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

article h4 {
  text-transform: uppercase;
  color: hsl(21, 62%, 45%);
  margin-bottom: 0.25rem;
}
.title {
  text-transform: capitalize;
  margin-bottom: 0.75rem;
  color: hsl(209, 34%, 30%);
}

.title {
  text-align: center;
  margin-bottom: 2rem;
}
.title h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.text {
  max-width: 35em;
  margin: 0 auto;
  margin-top: 2rem;
  line-height: 2;
  color: hsl(210, 22%, 49%);
}

.prev,
.next {
  position: absolute;
  top: 200px;
  transform: translateY(-50%);
  background: hsl(210, 22%, 49%);
  color: #fff;
  width: 1.25rem;
  height: 1.25rem;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s linear;
}
.prev:hover,
.next:hover {
  background: hsl(21, 62%, 45%);
}
.prev {
  left: 0;
}
.next {
  right: 0;
}
@media (min-width: 800px) {
  .text {
    max-width: 45em;
  }
  .prev,
  .next {
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
  }
}
*/