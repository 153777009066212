.container-about{
	width:80%;
	height: 300px;
	background-color: white;
	opacity: 60%;
	margin: auto;
	text-align: center;
	font-size: 50px;
	color: rgb(15 26 63);
	font-family: inherit;
}
.container-about h2{
	animation: fadein 7s ;
}
.ourMission{
	width:80%;
	height: auto;
	background-color: rgb(15 26 63);
	margin: auto;
	margin-top: 7%;
	color: white;
}
.ourMission h2{
	text-align: center;
	color:white;
	font-size: 50px;


}
.OUR-VALUES{
	width:80%;
	height:auto;
	background-color: white;
	margin: auto;
	margin-top: 7%;
	color: rgb(15 26 6);
}
.OUR-VALUES h2{
	text-align: center;
	color:rgb(15 26 63);
	font-size: 50px;
}
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {
.ourMission{
	width: auto;
	align-items: center;
}
.container-about{
	width: auto;
	margin: auto;
}
.OUR-VALUES{
	width: auto;
}
}